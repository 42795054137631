import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Card, CssBaseline } from '@mui/material';
import { TextEditor } from '../utilities/RichTextEditor/RichText';
// import { AuthPost } from '../utilities/Routing/Requests';
// import { toast } from 'react-toastify';
// import { useGlobalStore } from '../utilities/GlobalState';
import { ModulePage, Question } from '../interfaces/Interfaces';
import { EditAssignment } from './assignments/EditAssignment';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    moduleId: number;
    getModulePages: (moduleId: number) => void;
    setCreateModulePageOpen: (open: boolean) => void;
    setPage: (page?: ModulePage) => void;
    questions: Array<Question>;
    setQuestions: (question: Array<Question>) => void;
    rteRef: any;
    urlHashChange: () => void;
    page?: ModulePage;
    newType?: string;
}

export const EditModulePageModal = ({
    open,
    handleClose,
    moduleId,
    getModulePages,
    setCreateModulePageOpen,
    setPage,
    questions,
    setQuestions,
    rteRef,
    urlHashChange,
    page,
    newType,
}: Props) => {
    // const globalState = useGlobalStore((state) => state);
    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     const body = {
    //         UserEmail: data.get('email'),
    //         Password: data.get('password'),
    //         FirstName: data.get('firstName'),
    //         LastName: data.get('lastName'),
    //         Type: data.get('type') || '',
    //         OrgID: data.get('orgId'),
    //         active: true,
    //     };
    //     // fix this route
    //     AuthPost('/user/register', body, {
    //         'x-access-token': globalState.authToken,
    //     })
    //         .then((res) => {
    //             if (res?.status === 200) {
    //                 toast.success('Account Successfully Created!');
    //                 handleClose(true);
    //             }
    //         })
    //         .catch((err) => {
    //             toast.error(err.toString());
    //         });
    // };
    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '95%', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        width: '95%',
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        {!page ? 'Create New' : 'Edit'}
                    </Typography>
                    <Box
                        component="form"
                        // onSubmit={handleSubmit}
                        sx={{
                            width: '100%',
                            mt: 1,
                        }}
                    >
                        {(page &&
                            !page?.data?.startsWith('{"questions":') &&
                            !page?.data?.startsWith('<p>{"questions":')) ||
                        newType === 'text' ? (
                            <TextEditor
                                getModulePages={getModulePages}
                                moduleId={moduleId}
                                pageData={page}
                                setCreateModulePageOpen={
                                    setCreateModulePageOpen
                                }
                                setPage={setPage}
                                rteRef={rteRef}
                                urlHashChange={urlHashChange}
                            />
                        ) : (
                            <EditAssignment
                                getModulePages={getModulePages}
                                moduleId={moduleId}
                                page={page}
                                setPage={setPage}
                                questions={questions}
                                setQuestions={setQuestions}
                                setCreateAssignmentPageOpen={
                                    setCreateModulePageOpen
                                }
                            />
                        )}
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
