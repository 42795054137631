import * as React from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ModulePage, Question } from '../../interfaces/Interfaces';
import { AuthPost } from '../../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../../utilities/GlobalState';
import ClearIcon from '@mui/icons-material/Clear';
import { ReactSortable } from 'react-sortablejs';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { v1 as uuidv1 } from 'uuid';
import ImageUploader from '../ImageUploader';
import { BlobServiceClient } from '@azure/storage-blob';
import { SearchableDropdown } from '../../utilities/SearchableDropdown';
import { getHeaderIcon, headerIcons } from '../../utilities/Utilites';
export interface Props {
    moduleId: number;
    getModulePages: (moduleId: number) => void;
    setPage: (page?: ModulePage) => void;
    questions: Array<Question>;
    setQuestions: (question: Array<Question>) => void;
    setCreateAssignmentPageOpen?: (open: boolean) => void;
    page?: any;
}

export const EditAssignment = ({
    moduleId,
    getModulePages,
    setCreateAssignmentPageOpen,
    setPage,
    setQuestions,
    questions,
    page,
}: Props) => {
    const globalState = useGlobalStore((state) => state);
    const [type, setType] = React.useState('');
    const [pageNumber, setPageNumber] = React.useState(page?.pageNumber || '');
    const [title, setTitle] = React.useState(page?.title || '');
    const [headerIcon, setHeaderIcon] = React.useState(page?.headerIcon || '');
    const [loading, setLoading] = React.useState(false);
    const [newQuestionsLocal, setNewQuestionsLocal] =
        React.useState<Array<Question>>(questions);
    // const [questionList, setQuestionList] = React.useState<Array<any>>();
    const [file, setFile] = React.useState<
        Array<{
            assessmentDetailID: string;
            image: Blob | null;
        } | null>
    >([]);

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };

    const addMultipleChoiceInput = async (index: number) => {
        await setLoading(true);
        const originalQs = questions;
        const newQ = questions[index];
        await newQ.assessmentChoices.push('');
        const newQs = originalQs.map((q, i) => {
            if (i !== index) {
                return q;
            } else {
                return newQ;
            }
        });
        await setQuestions(newQs);
        await setLoading(false);
    };

    const onQuestionInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const originalQs = questions;
        const newQ = questions[index];
        newQ.assessmentDetail = e.target.value;
        const newQs = originalQs.map((q, i) => {
            if (i !== index) {
                return q;
            } else {
                return newQ;
            }
        });
        await setNewQuestionsLocal(newQs);
        await setQuestions(newQs);
    };

    const onQuestionRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const newQs = questions.filter((q, i) => {
            return i !== index;
        });
        await setNewQuestionsLocal(newQs);
        await setQuestions(newQs);
    };

    const onChoiceInputRemove = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        questionIndex: number,
        choiceIndex?: number,
    ) => {
        const originalQs = questions;
        const newQ = questions[questionIndex];
        let newQs;
        if (choiceIndex) {
            newQ.assessmentChoices = newQ.assessmentChoices.filter((c, i) => {
                return i !== choiceIndex;
            });
            newQs = originalQs.map((q, i) => {
                if (i !== questionIndex) {
                    return q;
                } else {
                    return newQ;
                }
            });
        } else {
            newQs = originalQs.filter((q, i) => i !== questionIndex);
        }
        await setNewQuestionsLocal(newQs);
        await setQuestions(newQs);
    };

    const onChoiceInputChange = async (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        questionIndex: number,
        choiceIndex: number,
    ) => {
        const originalQs = questions;
        const newQ = questions[questionIndex];
        newQ.assessmentChoices[choiceIndex] = e.target.value;
        const newQs = originalQs.map((q, i) => {
            if (i !== questionIndex) {
                return q;
            } else {
                return newQ;
            }
        });
        await setNewQuestionsLocal(newQs);
        await setQuestions(newQs);
    };

    const toggleAnswerCheck = async (
        questionIndex: number,
        choiceIndex: number,
    ) => {
        const originalQs = questions;
        const newQ = questions[questionIndex];
        if (questions[questionIndex].correctAnswer.includes(choiceIndex + 1)) {
            const newAnswers = questions[questionIndex].correctAnswer.filter(
                (answer) => answer !== choiceIndex + 1,
            );
            newQ.correctAnswer = newAnswers;
        } else {
            const newAnswers = questions[questionIndex].correctAnswer || [];
            (newAnswers as Array<any>).push(choiceIndex + 1);
            newQ.correctAnswer = newAnswers;
        }
        const newQs = originalQs.map((q, i) => {
            if (i !== questionIndex) {
                return q;
            } else {
                return newQ;
            }
        });
        await setNewQuestionsLocal(newQs);
        await setQuestions(newQs);
    };

    const removeQuestion = (ind: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onQuestionRemove(e, ind);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const removeInput = (qIn: number, chIn?: number) => {
        return (
            <IconButton
                onClick={(e: any) => {
                    onChoiceInputRemove(e, qIn, chIn);
                }}
            >
                <ClearIcon />
            </IconButton>
        );
    };

    const reorderQuestions = (newQuestions: Array<any>) => {
        const newOrder: Array<Question> = [];
        newQuestions.forEach((q) => {
            if (q?.key) {
                newOrder.push(questions[q.key]);
            }
        });
        setNewQuestionsLocal(newOrder);
        setQuestions(newOrder);
    };

    let localImageUrl: Array<string | null> = [];

    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || 'images-lms'; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob): Promise<string> => {
        const fileToSubmit = blobToFile(fileForSubmit);
        const blobName = `${new Date().getTime()}-${
            fileToSubmit?.name || 'create.png'
        }`; // Specify a default blob name if needed
        if (!fileToSubmit) {
            // check if the file is selected
            // alert('Please select an image to upload');
            return new Promise((res) => res(''));
        }
        if (!account || !sasToken || !containerName) {
            // check if the credentials are set
            alert(
                'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
            );
            return new Promise((res) => res(''));
        }
        try {
            const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
            return blobClient
                .uploadData(fileToSubmit, {
                    blobHTTPHeaders: { blobContentType: fileToSubmit?.type },
                })
                .then(() => {
                    const imageLink = `https://imageslms.blob.core.windows.net/${containerName}/${blobName}`;
                    AuthPost(
                        '/documents/adddocument',
                        {
                            type: fileToSubmit?.type,
                            moduleID: moduleId?.toString() || '0',
                            link: imageLink,
                            title: blobName,
                        },
                        {
                            'x-access-token': globalState.authToken,
                            canCache: true,
                        },
                    )
                        .then((res) => {
                            if (res?.status === 200) {
                                return res.data;
                            } else {
                                return [];
                            }
                        })
                        .catch((err) => {
                            toast.error(err.toString());
                        });
                    return imageLink;
                });
        } catch (error: any) {
            console.error(error); // Handle error
            toast.error(error?.toString());
        }
        return new Promise((res) =>
            res(
                `https://imageslms.blob.core.windows.net/${containerName}/${blobName}`,
            ),
        );
    };
    const changeImage = (newImage: string | null, i: number) => {
        const originalQs = questions;
        const newQ = questions[i];
        newQ.image = newImage || null;
        const newQs = originalQs.map((q, ind) => {
            if (ind !== i) {
                return q;
            } else {
                return newQ;
            }
        });
        setNewQuestionsLocal(newQs);
        setQuestions(newQs);
        localImageUrl[i] = newImage;
    };

    const mapQuestions = (qs: any) => {
        return qs?.map((question: any, i: number) => {
            if (question.type === 'mc') {
                return (
                    <Card
                        key={question.assessmentDetailID + 'mc' + i}
                        sx={{
                            width: '100%',
                            margin: '10px 0 10px 0',
                            overflow: 'auto',
                        }}
                    >
                        <Grid
                            container
                            sx={{
                                padding: '10px',
                            }}
                        >
                            <Grid xs={11}>
                                <Typography>
                                    {i + 1 + ') Multiple Choice'}
                                </Typography>
                            </Grid>
                            <Grid xs={1} sx={{ alignSelf: 'center' }}>
                                <span
                                    className="glyphicon-move"
                                    aria-hidden="true"
                                >
                                    <DragIndicatorIcon />
                                </span>
                            </Grid>
                        </Grid>
                        <React.Fragment
                            key={question.assessmentDetailID + 'mc-uploader'}
                        >
                            <ImageUploader
                                id={
                                    question.assessmentDetailID +
                                    'mc-uploader' +
                                    i
                                }
                                key={
                                    question.assessmentDetailID +
                                    'mc-uploader' +
                                    i
                                }
                                setFile={(newFile) => {
                                    const newFileArray = file;
                                    const obj = {
                                        assessmentDetailID:
                                            question?.assessmentDetailID,
                                        image: newFile,
                                    };
                                    newFileArray[i] = obj;
                                    setFile(newFileArray);
                                    return;
                                }}
                                imageUrl={question?.image || null}
                                file={file[i]?.image || null}
                                changeUrl={(e) => changeImage(e, i)}
                                deleteCallback={() => {}}
                                center
                            />
                        </React.Fragment>
                        <div style={{ padding: '10px' }}>
                            <TextField
                                fullWidth
                                name="question"
                                label="Question"
                                type="text"
                                defaultValue={
                                    question.assessmentDetail ||
                                    question.question
                                }
                                onChange={(e) => onQuestionInputChange(e, i)}
                                InputProps={{
                                    endAdornment: removeQuestion(i),
                                }}
                            />
                        </div>
                        <Grid
                            container
                            sx={{
                                padding: '10px',
                            }}
                        >
                            <Grid xs={1} sx={{ alignSelf: 'center' }}>
                                <IconButton
                                    key={'add-answers-iconbutton'}
                                    onClick={() => addMultipleChoiceInput(i)}
                                >
                                    <AddCircleIcon
                                        sx={{
                                            paddingTop: '5px',
                                            width: '50px',
                                            height: '50px',
                                            color: 'green',
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid xs={11}>
                                {(
                                    question?.assessmentChoices ||
                                    question?.choices
                                ).map((choice: any, ind: number) => (
                                    <Grid
                                        container
                                        key={'question-' + i + '-choice-' + ind}
                                        style={{ padding: '5px' }}
                                    >
                                        <Grid
                                            xs={11}
                                            sx={{ alignSelf: 'center' }}
                                        >
                                            <TextField
                                                fullWidth
                                                defaultValue={choice}
                                                name="choice"
                                                label="Choice"
                                                type="text"
                                                onChange={(e) =>
                                                    onChoiceInputChange(
                                                        e,
                                                        i,
                                                        ind,
                                                    )
                                                }
                                                InputProps={{
                                                    endAdornment: removeInput(
                                                        i,
                                                        ind,
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={1}
                                            sx={{ alignSelf: 'center' }}
                                        >
                                            <Checkbox
                                                checked={question.correctAnswer.includes(
                                                    ind + 1,
                                                )}
                                                onChange={() =>
                                                    toggleAnswerCheck(i, ind)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Card>
                );
            } else if (question.type === 'sa') {
                return (
                    <Card
                        key={question.assessmentDetailID + 'sa' + i}
                        sx={{
                            width: '100%',
                            margin: '10px 0 10px 0',
                            overflow: 'auto',
                        }}
                    >
                        <Grid
                            container
                            sx={{
                                padding: '10px',
                            }}
                        >
                            <Grid xs={11}>
                                <Typography>
                                    {i + 1 + ') Short Answer'}
                                </Typography>
                            </Grid>
                            <Grid xs={1} sx={{ alignSelf: 'center' }}>
                                <span
                                    className="glyphicon-move"
                                    aria-hidden="true"
                                >
                                    <DragIndicatorIcon />
                                </span>
                            </Grid>
                        </Grid>
                        <React.Fragment
                            key={question.assessmentDetailID + 'mc-uploader'}
                        >
                            <ImageUploader
                                id={
                                    question.assessmentDetailID +
                                    'sa-uploader' +
                                    i
                                }
                                key={
                                    question.assessmentDetailID +
                                    'sa-uploader' +
                                    i
                                }
                                setFile={(newFile) => {
                                    const newFileArray = file;
                                    const obj = {
                                        assessmentDetailID:
                                            question?.assessmentDetailID,
                                        image: newFile,
                                    };

                                    newFileArray[i] = obj;
                                    setFile(newFileArray);
                                    return;
                                }}
                                imageUrl={question?.image || null}
                                file={file[i]?.image || null}
                                changeUrl={(e) => changeImage(e, i)}
                                deleteCallback={() => {}}
                                center
                            />
                        </React.Fragment>
                        <div style={{ padding: '10px' }}>
                            <TextField
                                fullWidth
                                name="question"
                                label="Question"
                                type="text"
                                defaultValue={
                                    question.assessmentDetail ||
                                    question.question
                                }
                                onChange={(e) => onQuestionInputChange(e, i)}
                                InputProps={{
                                    endAdornment: removeInput(i),
                                }}
                            />
                        </div>
                    </Card>
                );
            } else if (question.type === 'la') {
                return (
                    <Card
                        key={question.assessmentDetailID + 'la' + i}
                        sx={{
                            width: '100%',
                            margin: '10px 0 10px 0',
                            overflow: 'auto',
                        }}
                    >
                        <Grid
                            container
                            sx={{
                                padding: '10px',
                            }}
                        >
                            <Grid xs={11}>
                                <Typography>
                                    {i + 1 + ') Extended Response'}
                                </Typography>
                            </Grid>
                            <Grid xs={1} sx={{ alignSelf: 'center' }}>
                                <span
                                    className="glyphicon-move"
                                    aria-hidden="true"
                                >
                                    <DragIndicatorIcon />
                                </span>
                            </Grid>
                        </Grid>
                        <React.Fragment
                            key={question.assessmentDetailID + 'la-uploader'}
                        >
                            <ImageUploader
                                id={
                                    question.assessmentDetailID +
                                    'la-uploader' +
                                    i
                                }
                                key={
                                    question.assessmentDetailID +
                                    'la-uploader' +
                                    i
                                }
                                setFile={(newFile) => {
                                    const newFileArray = file;
                                    const obj = {
                                        assessmentDetailID:
                                            question?.assessmentDetailID,
                                        image: newFile,
                                    };

                                    newFileArray[i] = obj;
                                    setFile(newFileArray);
                                    return;
                                }}
                                imageUrl={question?.image || null}
                                file={file[i]?.image || null}
                                changeUrl={(e) => changeImage(e, i)}
                                deleteCallback={() => {}}
                                center
                            />
                        </React.Fragment>

                        <div style={{ padding: '10px' }}>
                            <TextField
                                fullWidth
                                name="question"
                                label="Question"
                                type="text"
                                defaultValue={
                                    question.assessmentDetail ||
                                    question.question
                                }
                                onChange={(e) => onQuestionInputChange(e, i)}
                                InputProps={{
                                    endAdornment: removeInput(i),
                                }}
                            />
                        </div>
                    </Card>
                );
            } else {
                return <div key={'missing-question-' + i}></div>;
            }
        });
    };

    // React.useEffect(() => {
    //     setQuestionList(mapQuestions(questions));
    // }, [questions]);

    const addCurrentlySelectedType = async () => {
        await setLoading(true);
        const currentQuestions: Array<Question> = questions;
        currentQuestions.push({
            assessmentDetail: '',
            assessmentChoices: [''],
            assessmentDetailID: uuidv1(),
            modulePageID: page?.id,
            type: type,
            correctAnswer: [],
        });
        setNewQuestionsLocal(currentQuestions);
        setQuestions(currentQuestions);
        await setLoading(false);
    };

    const updateAssessments = (body: {
        pageId?: any;
        data: string;
        pageNumber: string;
        title: any;
        modulePageID?: any;
    }) => {
        // const bodyArray = JSON.parse(body.data);
        const newQuestions = newQuestionsLocal;
        JSON.parse(body.data)?.questions?.forEach(
            async (dataItem: any, i: number) => {
                const currentQuestionImage = file.filter((x) => {
                    return (
                        x?.assessmentDetailID === dataItem?.assessmentDetailID
                    );
                })[0]?.image;
                if (currentQuestionImage) {
                    handleSubmitImage(currentQuestionImage as any).then(
                        (imageRes) => {
                            if (dataItem?.assessmentDetailID?.length) {
                                const editBody = {
                                    assessmentDetail:
                                        dataItem.assessmentDetail ||
                                        dataItem.question ||
                                        '',
                                    assessmentChoices:
                                        dataItem?.choices ||
                                        dataItem?.assessmentChoices ||
                                        null,
                                    assessmentDetailID: dataItem
                                        ?.assessmentDetailID?.length
                                        ? dataItem?.assessmentDetailID
                                        : uuidv1(),
                                    manualReview: dataItem?.manualReview || 0,
                                    type: dataItem?.type,
                                    correctAnswer: dataItem?.correctAnswer,
                                    image: dataItem?.image || imageRes,
                                    modulePageID:
                                        body?.modulePageID || page?.id,
                                    moduleID: moduleId,
                                };
                                AuthPost(
                                    '/assessmentdetail/editassessment',
                                    editBody,
                                    {
                                        'x-access-token': globalState.authToken,
                                        canCache: true,
                                    },
                                )
                                    .then((res) => {
                                        if (res?.status === 200) {
                                            // getModulePages(moduleId);
                                            // if (setCreateAssignmentPageOpen) {
                                            //     setCreateAssignmentPageOpen(false);
                                            // }
                                            // setPage();
                                            return res.data;
                                        } else {
                                            return [];
                                        }
                                    })
                                    .catch((err) => {
                                        toast.error(err.toString());
                                    });
                            } else {
                                const addGuidToBody: Question = {
                                    assessmentDetail:
                                        dataItem.assessmentDetail ||
                                        dataItem.question,
                                    assessmentChoices:
                                        dataItem?.choices ||
                                        dataItem?.assessmentChoices ||
                                        null,
                                    manualReview: dataItem?.manualReview || 0,
                                    type: dataItem?.type,
                                    correctAnswer: dataItem?.correctAnswer,
                                    modulePageID:
                                        body?.modulePageID || page?.id,
                                    assessmentDetailID: dataItem
                                        ?.assessmentDetailID?.length
                                        ? dataItem?.assessmentDetailID
                                        : uuidv1(),
                                    image: dataItem?.image?.length
                                        ? dataItem?.image
                                        : imageRes,
                                    moduleID: moduleId,
                                };
                                newQuestions.splice(i, 1, addGuidToBody);
                                AuthPost(
                                    '/assessmentdetail/addassessmentdetail',
                                    addGuidToBody,
                                    {
                                        'x-access-token': globalState.authToken,
                                        canCache: true,
                                    },
                                )
                                    .then((res) => {
                                        setNewQuestionsLocal(newQuestions);
                                        setQuestions(newQuestions);
                                        if (
                                            res?.status === 200 ||
                                            res?.status === 204
                                        ) {
                                            // const current Questions: Array<any> = questions;
                                            // currentQuestions.push({
                                            //     assessmentDetailID: body.assessmentDetailID,
                                            //     question: '',
                                            //     choices: [''],
                                            //     type: type,
                                            //     correctAnswer: [],
                                            // });
                                            return res.data;
                                        } else {
                                            return [];
                                        }
                                    })
                                    .catch((err) => {
                                        toast.error(err.toString());
                                    });
                            }
                        },
                    );
                } else {
                    if (dataItem?.assessmentDetailID?.length) {
                        const editBody = {
                            assessmentDetail:
                                dataItem.assessmentDetail ||
                                dataItem.question ||
                                '',
                            assessmentChoices:
                                dataItem?.choices ||
                                dataItem?.assessmentChoices ||
                                null,
                            assessmentDetailID: dataItem?.assessmentDetailID
                                ?.length
                                ? dataItem?.assessmentDetailID
                                : uuidv1(),
                            modulePageID: body?.modulePageID || page?.id,
                            manualReview: dataItem?.manualReview || 0,
                            type: dataItem?.type,
                            correctAnswer: dataItem?.correctAnswer,
                            image: dataItem?.image || null,
                            moduleID: moduleId,
                        };
                        AuthPost('/assessmentdetail/editassessment', editBody, {
                            'x-access-token': globalState.authToken,
                            canCache: true,
                        })
                            .then((res) => {
                                if (res?.status === 200) {
                                    // getModulePages(moduleId);
                                    // if (setCreateAssignmentPageOpen) {
                                    //     setCreateAssignmentPageOpen(false);
                                    // }
                                    // setPage();
                                    return res.data;
                                } else {
                                    return [];
                                }
                            })
                            .catch((err) => {
                                toast.error(err.toString());
                            });
                    } else {
                        const addGuidToBody: Question = {
                            assessmentDetail:
                                dataItem.assessmentDetail || dataItem.question,
                            assessmentChoices:
                                dataItem?.choices ||
                                dataItem?.assessmentChoices ||
                                null,
                            manualReview: dataItem?.manualReview || 0,
                            type: dataItem?.type,
                            correctAnswer: dataItem?.correctAnswer,
                            modulePageID: body?.modulePageID || page?.id,
                            assessmentDetailID: dataItem?.assessmentDetailID
                                ?.length
                                ? dataItem?.assessmentDetailID
                                : uuidv1(),
                            image: dataItem?.image || null,
                            moduleID: moduleId,
                        };
                        newQuestions.splice(i, 1, addGuidToBody);
                        AuthPost(
                            '/assessmentdetail/addassessmentdetail',
                            addGuidToBody,
                            {
                                'x-access-token': globalState.authToken,
                                canCache: true,
                            },
                        )
                            .then((res) => {
                                setNewQuestionsLocal(newQuestions);
                                setQuestions(newQuestions);
                                if (
                                    res?.status === 200 ||
                                    res?.status === 204
                                ) {
                                    // const current Questions: Array<any> = questions;
                                    // currentQuestions.push({
                                    //     assessmentDetailID: body.assessmentDetailID,
                                    //     question: '',
                                    //     choices: [''],
                                    //     type: type,
                                    //     correctAnswer: [],
                                    // });
                                    return res.data;
                                } else {
                                    return [];
                                }
                            })
                            .catch((err) => {
                                toast.error(err.toString());
                            });
                    }
                }
            },
        );
        // getModulePages(moduleId);
        // if (setCreateAssignmentPageOpen) {
        //     setCreateAssignmentPageOpen(false);
        // }
        // setPage();
    };

    const saveModulePage = async (modId: number, pNumber: string) => {
        setLoading(true);
        let data: Array<any> = [];
        const url =
            page?.id > 0
                ? '/organization/updatemodulepage'
                : '/organization/savemodulepage';

        const body2 =
            page && page?.id > 0
                ? {
                      // update
                      pageId: page?.id,
                      //   modulePageID: page?.id,
                      data: JSON.stringify({ questions: newQuestionsLocal }),
                      pageNumber: pNumber,
                      title: title || '',
                      headerIcon: headerIcon,
                  }
                : {
                      // save
                      moduleId: modId,
                      modulePageGUID: uuidv1(),
                      data: JSON.stringify({ questions: newQuestionsLocal }),
                      pageNumber: pNumber,
                      title: title || '',
                      headerIcon: headerIcon,
                  };
        await AuthPost(url, body2, {
            'x-access-token': globalState.authToken,
            canCache: true,
        })
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    data = res?.data;
                    return res.data;
                } else {
                    return [];
                }
            })

            .then((res1) => {
                getModulePages(moduleId);
                if (setCreateAssignmentPageOpen) {
                    setCreateAssignmentPageOpen(false);
                }
                setPage();
                updateAssessments(
                    // page?.id > 0
                    //     ?
                    // Object.values(res1?.[0])?.[0]
                    {
                        pageId: page?.id,
                        modulePageID:
                            Object.values(res1?.[0])[0] ||
                            body2?.modulePageGUID,
                        data: JSON.stringify({ questions: newQuestionsLocal }),
                        pageNumber: pNumber,
                        title: title || '',
                    },
                    // : {
                    //       moduleId: modId,
                    //       modulePageID: page.id,
                    //       data: JSON.stringify({ questions: newQuestionsLocal }),
                    //       pageNumber: pNumber,
                    //       title: title || '',
                    //   }
                );
            })
            // .then((res) => {
            //     if (res?.status === 200 || res?.status === 204) {
            //         getModulePages(moduleId);
            //         if (setCreateAssignmentPageOpen) {
            //             setCreateAssignmentPageOpen(false);
            //         }
            //         setPage();
            //         data = res?.data;
            //         return res.data;
            //     } else {
            //         return [];
            //     }
            // })
            .catch((err) => {
                toast.error(err.toString());
            });
        setLoading(false);
        return data;
    };

    React.useEffect(() => {
        const imageArray: Array<string | null> = [];
        if (questions && questions?.length) {
            questions?.forEach((q) => {
                if (file?.length !== questions?.length) {
                    const newFileArray = file;
                    newFileArray.push({
                        assessmentDetailID: q.assessmentDetailID,
                        image: null,
                    });
                    setFile(newFileArray);
                }
                if (imageArray?.length !== questions?.length) {
                    imageArray.push(q?.image || null);
                }
            });
        }
        localImageUrl = imageArray;
    }, [questions]);

    return (
        <Box>
            <TextField
                margin="normal"
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                defaultValue={title}
                autoFocus
                onChange={(e) => setTitle(e?.target?.value)}
            />
            <Grid container>
                <Grid md={2}>
                    <div
                        style={{
                            height: '50px',
                            width: '95px',
                            fontSize: 'x-large',
                        }}
                    >
                        {getHeaderIcon(headerIcon, '47px')}
                    </div>
                </Grid>
                <Grid md={10}>
                    <Grid container>
                        <SearchableDropdown
                            label="Page Header Icon"
                            selected={{ name: page?.headerIcon || 'none' }}
                            allOptions={headerIcons.map((item) => {
                                return { name: item };
                            })}
                            searchKey="name"
                            callBackOnSelect={(selectedOption) => {
                                setHeaderIcon(selectedOption?.name);
                            }}
                        />
                    </Grid>
                    <Grid md={6}>
                        <TextField
                            required
                            fullWidth
                            id="page"
                            label="Page Number"
                            name="page"
                            defaultValue={pageNumber}
                            autoFocus
                            onChange={(e) =>
                                setPageNumber(e?.target?.value?.trim())
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid xs={10}>
                    <FormControl fullWidth>
                        <InputLabel id="select-type-label">Type</InputLabel>
                        <Select
                            labelId="select-type"
                            id="demo-simple-select-type"
                            value={type}
                            label="Type"
                            onChange={handleTypeChange}
                        >
                            <MenuItem value={'mc'}>Multiple Choice</MenuItem>
                            <MenuItem value={'sa'}>Short Answer</MenuItem>
                            <MenuItem value={'la'}>Extended Response</MenuItem>
                            {/* <MenuItem value={'matching'}>
                                        Matching
                                    </MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ height: '56px' }}
                        onClick={addCurrentlySelectedType}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            {questions?.length ? (
                <ReactSortable
                    // dragClass="sortableDrag"
                    list={JSON.parse(JSON.stringify(questions))}
                    setList={(newState, _, { dragging }) => {
                        if (!dragging) {
                            return;
                        }
                        reorderQuestions(newState);
                        setQuestions(newState as any);
                        setNewQuestionsLocal(newState as any);
                    }}
                    animation={200}
                    easing="ease-out"
                >
                    {!loading && mapQuestions(questions)}
                </ReactSortable>
            ) : (
                <></>
            )}
            <Button
                variant="contained"
                size="small"
                onClick={() => {
                    saveModulePage(moduleId, pageNumber);
                }}
            >
                Save
            </Button>
        </Box>
    );
};
