import * as React from 'react';
import { useEffect, useState } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import Loading from '../utilities/loading/Loading';
import { useGlobalStore } from '../utilities/GlobalState';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

interface Props {
    changeUrl: (newUrl: string | null) => void;
    imageUrl: string | null;
    setFile: (newFile: Blob | null) => void;
    file: Blob | null;
    deleteCallback: () => void;
    center?: boolean;
    id?: any;
}

const ImageUploader = ({
    changeUrl,
    imageUrl,
    setFile,
    file,
    deleteCallback,
    center,
    id,
}: Props) => {
    const [imageDetails, setImageDetails] = useState<{
        name: string;
        url: string;
    }>();
    const [loading, setLoading] = useState(false);
    const [localFile, setLocalFile] = useState<any>();
    const globalState = useGlobalStore((state) => state);
    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || 'images-lms'; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    //fetch all images
    const fetchImages = async () => {
        if (!account || !sasToken || !containerName) {
            // check if the credentials are set
            alert(
                'Please make sure you have set the Azure Storage credentials in the .env file',
            );
            return;
        }
        try {
            setLoading(true); // Turn on loading
            const blobItems = containerClient?.listBlobsFlat(); // get all blobs in the container
            for await (const blob of blobItems) {
                const tempBlockBlobClient = containerClient?.getBlockBlobClient(
                    blob?.name,
                ); // get the blob url

                if (imageUrl && tempBlockBlobClient?.url?.includes(imageUrl)) {
                    setImageDetails({
                        name: blob.name,
                        url: tempBlockBlobClient.url,
                    }); // set the imageUrls state
                    changeUrl(tempBlockBlobClient?.url);
                }
            }
        } catch (error: any) {
            console.error(error); // Handle error
            toast.error(error?.toString());
        } finally {
            setLoading(false); // Turn off loading
        }
    };

    const handleClear = async () => {
        setFile(null);
    };

    // delete an Image
    const handleDelete = async (blobName: string) => {
        if (!account || !sasToken || !containerName) {
            // check if the credentials are set
            alert(
                'Please make sure you have set the Azure Storage credentials in the .env file',
            );
            return;
        }
        try {
            setLoading(true); // Turn on loading
            const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
            await blobClient.delete(); // delete the blob
            setImageDetails(undefined); // set the urls array to the imageUrls state
            setFile(null);
            changeUrl(null);
            deleteCallback();
            fetchImages(); // fetch all images again after the delete is completed
        } catch (error: any) {
            console.log(error); // Handle error
            toast.error(error?.toString());
        } finally {
            setLoading(false); //
        }
    };

    // fetch all images when the page loads
    useEffect(() => {
        fetchImages();
    }, []);

    // Helper function to get the image name without extension
    const getImageNameWithoutExtension = (filename: string) => {
        const dotIndex = filename.lastIndexOf('.');
        return dotIndex !== -1 ? filename.slice(0, dotIndex) : filename;
    };
    return (
        <div
            className={center ? 'Center-Content' : ''}
            key={id || 'ImageUploader'}
            id={id || 'ImageUploader'}
        >
            {loading ? <Loading /> : <></>}
            <div>
                {!imageDetails ? (
                    <>
                        <form>
                            <div>
                                {file || localFile ? (
                                    <div style={{ margin: 'auto' }}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={handleClear}
                                        >
                                            Remove Image
                                        </Button>
                                        {file || localFile ? (
                                            <img
                                                // height="10%"
                                                style={{ width: '100%' }}
                                                src={URL.createObjectURL(
                                                    file || localFile,
                                                )}
                                                alt="uploader missing image"
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        className="upload-form_inputs"
                                        key={id || 'ImageUploaderButton'}
                                        id={id || 'ImageUploaderButton'}
                                    >
                                        <label
                                            title="Upload Image"
                                            htmlFor={'fileInput' + id}
                                        >
                                            <UploadFileIcon
                                                sx={{
                                                    height: '100px',
                                                    width: '100px',
                                                    color: 'rgb(84, 162, 84)',
                                                }}
                                            />
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            id={'fileInput' + id}
                                            onChange={(e) => {
                                                setFile(
                                                    e?.target?.files?.[0] ||
                                                        null,
                                                );
                                                setLocalFile(
                                                    e?.target?.files?.[0] ||
                                                        null,
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </form>
                    </>
                ) : (
                    <div
                        key={id || 'ImageUploader1'}
                        id={id || 'ImageUploader1'}
                    >
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => handleDelete(imageDetails?.name)}
                        >
                            Permanently Delete Image
                        </Button>
                        <img
                            style={{ width: '100%' }}
                            src={imageDetails?.url}
                            alt="no pic"
                        />
                        <h3 style={{ width: '80%' }}>
                            {getImageNameWithoutExtension(imageDetails?.name)}
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUploader;
