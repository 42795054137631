import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../utilities/GlobalState';
import {
    ApiOrganization,
    GlobalState,
    OrganizationUser,
} from '../interfaces/Interfaces';
import { BlobServiceClient } from '@azure/storage-blob';
import ImageUploader from '../modules/ImageUploader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SearchableDropdown } from '../utilities/SearchableDropdown';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    organizations: Array<ApiOrganization>;
}

export const CreateClassModal = ({
    open,
    handleClose,
    organizations,
}: Props) => {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [file, setFile] = React.useState<Blob | null>(null);
    const [selectedInstructor, setSelectedInstructor] =
        React.useState<OrganizationUser | null>(null);
    const [selectedOrganization, setSelectedOrganization] = React.useState<
        string | null
    >(null);
    const [selectedOrganizationObj, setSelectedOrganizationObj] =
        React.useState<ApiOrganization | null>(null);
    const [instructors, setInstructors] = React.useState<
        Array<OrganizationUser>
    >([]);

    const globalState = useGlobalStore((state: GlobalState) => state);
    if (
        (globalState?.user?.type?.includes('Instructor') ||
            globalState?.user?.type?.includes('OrganizationAdmin')) &&
        !selectedOrganization
    ) {
        const selectedOrg = organizations?.filter(
            (org) => globalState?.user?.orgId?.toString() === org.id.toString(),
        )[0];
        setSelectedOrganizationObj(selectedOrg);
        const inst = selectedOrg?.users?.length
            ? selectedOrg?.users?.filter((user: OrganizationUser) => {
                  return user?.type?.includes('Instructor');
              })
            : [];
        setInstructors(inst);
        setSelectedOrganization(globalState?.user?.orgId?.toString() || '');
        if (globalState?.user?.type?.includes('Instructor')) {
            setSelectedInstructor(
                inst.filter(
                    (ins) =>
                        ins?.id?.toString() ===
                        globalState?.user?.id?.toString(),
                )[0],
            );
        }
    }

    let localImageUrl = null;

    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || 'images-lms'; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob): Promise<string> => {
        const fileToSubmit = blobToFile(fileForSubmit);
        const blobName = `${new Date().getTime()}-${
            fileToSubmit?.name || 'create.png'
        }`; // Specify a default blob name if needed
        if (!fileToSubmit) {
            // check if the file is selected
            // alert('Please select an image to upload');
            return new Promise((res) => res(''));
        }
        if (!account || !sasToken || !containerName) {
            // check if the credentials are set
            alert(
                'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
            );
            return new Promise((res) => res(''));
        }
        try {
            const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
            return blobClient
                .uploadData(fileToSubmit, {
                    blobHTTPHeaders: { blobContentType: fileToSubmit?.type },
                })
                .then(() => {
                    const imageLink = `https://imageslms.blob.core.windows.net/${containerName}/${blobName}`;
                    AuthPost(
                        '/documents/adddocument',
                        {
                            type: fileToSubmit?.type,
                            moduleID: '0',
                            link: imageLink,
                            title: blobName,
                        },
                        {
                            'x-access-token': globalState.authToken,
                            canCache: true,
                        },
                    )
                        .then((res) => {
                            if (res?.status === 200) {
                                return res.data;
                            } else {
                                return [];
                            }
                        })
                        .catch((err) => {
                            toast.error(err.toString());
                        });
                    return imageLink;
                });
        } catch (error: any) {
            console.error(error); // Handle error
            toast.error(error?.toString());
        }
        return new Promise((res) =>
            res(
                `https://imageslms.blob.core.windows.net/${containerName}/${blobName}`,
            ),
        );
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (file) {
            handleSubmitImage(file).then((imageRes) => {
                const body = {
                    name: data.get('name'),
                    description: data.get('description'),
                    year: data.get('year'),
                    term: data.get('term'),
                    image: imageRes,
                    startDate: new Date(startDate as any).toISOString(),
                    endDate: new Date(endDate as any).toISOString(),
                    orgID: selectedOrganizationObj?.id?.toString(),
                    userID: selectedInstructor?.id || globalState?.user?.id,
                };
                AuthPost('/class/addclass', body, {
                    'x-access-token': globalState.authToken,
                })
                    .then((res) => {
                        if (res?.status === 200) {
                            toast.success('Class Successfully Created!');
                            handleClose(true);
                        }
                    })
                    .catch((err) => {
                        toast.error(err.toString());
                    });
            });
        } else {
            const body = {
                name: data.get('name'),
                description: data.get('description'),
                year: data.get('year'),
                term: data.get('term'),
                startDate: new Date(startDate as any).toISOString(),
                endDate: new Date(endDate as any).toISOString(),
                orgID: selectedOrganizationObj?.id?.toString(),
                userID: selectedInstructor?.id || globalState?.user?.id,
            };
            AuthPost('/class/addclass', body, {
                'x-access-token': globalState.authToken,
            })
                .then((res) => {
                    if (res?.status === 200) {
                        toast.success('Class Successfully Created!');
                        handleClose(true);
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
    };

    const changeImage = (newImage: string | null) => {
        localImageUrl = newImage;
    };

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ width: '800px', margin: '10px' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Class
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="description"
                            label="Description"
                            name="description"
                        />
                        <TextField
                            margin="normal"
                            required
                            sx={{ width: '50%' }}
                            id="year"
                            label="Year"
                            name="year"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            sx={{ width: '50%' }}
                            id="term"
                            label="Term"
                            name="term"
                        />
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            sx={{ width: '50%' }}
                            onChange={(newValue: any) => {
                                setStartDate(newValue);
                            }}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue: any) => {
                                setEndDate(newValue);
                            }}
                            sx={{ width: '50%' }}
                        />
                        <ImageUploader
                            setFile={(newFile) => setFile(newFile)}
                            imageUrl={localImageUrl}
                            file={file}
                            changeUrl={changeImage}
                            deleteCallback={() => {}}
                            center
                        />

                        {organizations?.length &&
                        (globalState?.user?.type?.includes('APTAdmin') ||
                            globalState?.user?.type?.includes(
                                'OrganizationAdmin',
                            )) ? (
                            <div
                                style={{
                                    marginTop: '16px',
                                    marginBottom: '16px',
                                }}
                            >
                                <SearchableDropdown
                                    label="Organization"
                                    selected={selectedOrganizationObj}
                                    allOptions={organizations}
                                    searchKey="name"
                                    callBackOnSelect={(selectedOption) => {
                                        setSelectedOrganizationObj(
                                            selectedOption,
                                        );
                                        if (selectedOption) {
                                            setSelectedOrganization(
                                                selectedOption.id.toString(),
                                            );
                                            const inst = selectedOption?.users
                                                ?.length
                                                ? selectedOption?.users?.filter(
                                                      (
                                                          user: OrganizationUser,
                                                      ) => {
                                                          return user?.type?.includes(
                                                              'Instructor',
                                                          );
                                                      },
                                                  )
                                                : [];
                                            setInstructors(inst);
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        {selectedOrganization &&
                        (globalState?.user?.type?.includes('APTAdmin') ||
                            globalState?.user?.type?.includes(
                                'OrganizationAdmin',
                            )) ? (
                            <div>
                                <SearchableDropdown
                                    label="Instructor"
                                    selected={selectedInstructor}
                                    allOptions={instructors}
                                    searchKey="lastName"
                                    callBackOnSelect={(selectedOption) => {
                                        setSelectedInstructor(selectedOption);
                                    }}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            CREATE CLASS
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
