import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, Grid, IconButton, Typography } from '@mui/material';
import { Module, ModulePage } from '../interfaces/Interfaces';
import Slider from 'react-slick';
import { PagePreviewForCarousel } from './PagePreviewForCarousel';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import Overlay from '../utilities/Overlay/Overlay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface Props {
    module?: Module;
    toggleModuleDetails: () => void;
    addModuleButton?: (modID: number) => any;
    docManagement?: boolean;
}

export const ModulePreviewDetails = ({
    module,
    addModuleButton,
    toggleModuleDetails,
    docManagement,
}: Props) => {
    const globalState = useGlobalStore((state) => state);
    const settings = {
        dots: true,
        adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
    };

    const [pages, setPages] = React.useState<Array<ModulePage>>();
    const [docs, setDocs] = React.useState<Array<any>>();

    const getModulePages = async (moduleId: number) => {
        let data: Array<any> = [];
        return AuthPost(
            '/organization/modulepages',
            { moduleId: moduleId },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    data = res?.data;
                    setPages(
                        data?.sort(
                            (a: any, b: any) =>
                                Number(a.pageNumber) - Number(b.pageNumber),
                        ),
                    );
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const getModuleDocuments = async (moduleId: number) => {
        return AuthPost(
            '/documents/getmoduledocuments',
            { moduleID: moduleId?.toString(), type: 'application/pdf' },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    setDocs(res?.data);
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        if (module) {
            getModulePages(module.id);
            getModuleDocuments(module.id);
        }
    }, [module]);
    return (
        <Card
            sx={{
                // width: '800px',
                margin: '10px',
                outline: 'none',
                overflow: 'auto',
            }}
        >
            <Grid container>
                <Grid md={4} style={{ alignContent: 'space-around' }}>
                    <IconButton onClick={toggleModuleDetails}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid md={4}>
                    <h3 style={{ textAlign: 'center' }}>
                        {module?.code + ': ' + module?.title}
                    </h3>
                </Grid>
                <Grid md={4}></Grid>
            </Grid>
            <Box
                sx={{
                    margin: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid
                    container
                    style={{
                        padding: '10px 10px 10px 10px',
                    }}
                >
                    {addModuleButton && module ? (
                        <Grid container direction="row">
                            <Grid xs={12} sx={{ padding: '5px' }}>
                                {addModuleButton(module.id)}
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid container direction="row">
                        <Grid xs={6} sx={{ padding: '5px' }}>
                            {module?.image?.length ? (
                                <div
                                    className="Module-Overview-Details"
                                    style={{
                                        // margin: '10px',
                                        textAlign: 'center',
                                        alignContent: 'center',
                                    }}
                                >
                                    <img
                                        style={{ width: '50%' }}
                                        src={module?.image || ''}
                                        alt="Module Details Missing Image"
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid xs={6} sx={{ padding: '5px' }}>
                            <div className="Module-Overview-Details">
                                <div className="Module-Overview-Detail-Item">
                                    Duration: {module?.duration}
                                </div>
                                <div className="Module-Overview-Detail-Item">
                                    Concepts: {module?.concepts}
                                </div>
                                <div className="Module-Overview-Detail-Item">
                                    Outcomes:{' '}
                                    <div>
                                        {module?.outcomes?.length
                                            ? JSON.parse(module?.outcomes)?.map(
                                                  (cert: any, i: number) => {
                                                      if (
                                                          module
                                                              ?.outcomesDetails
                                                              ?.length
                                                      ) {
                                                          return (
                                                              <>
                                                                  <div>
                                                                      <Typography>
                                                                          {i +
                                                                              1 +
                                                                              ') ' +
                                                                              cert +
                                                                              ': ' +
                                                                              JSON.parse(
                                                                                  module?.outcomesDetails,
                                                                              )?.[
                                                                                  i
                                                                              ]}
                                                                      </Typography>
                                                                  </div>
                                                              </>
                                                          );
                                                      }
                                                      return <></>;
                                                  },
                                              )
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid xs={6} sx={{ padding: '5px' }}>
                            <div className="Module-Overview-Details">
                                <div className="Module-Overview-Detail-Item">
                                    Description: {module?.description}
                                </div>
                                <div className="Module-Overview-Detail-Item">
                                    Summary: {module?.summary}
                                </div>
                                <div className="Module-Overview-Detail-Item">
                                    Instructor Notes: {module?.instructorNotes}
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={6} sx={{ padding: '5px' }}>
                            <div className="Module-Overview-Details">
                                <div className="Module-Overview-Detail-Item">
                                    Equipment:{' '}
                                    <div>
                                        {module?.equipments?.length
                                            ? JSON.parse(
                                                  module?.equipments,
                                              )?.map((quip: any, i: number) => {
                                                  if (
                                                      module?.equipmentsDetails
                                                          ?.length
                                                  ) {
                                                      return (
                                                          <>
                                                              <div>
                                                                  <Typography>
                                                                      {i +
                                                                          1 +
                                                                          ') ' +
                                                                          quip +
                                                                          ': ' +
                                                                          JSON.parse(
                                                                              module?.equipmentsDetails,
                                                                          )?.[
                                                                              i
                                                                          ]}
                                                                  </Typography>
                                                              </div>
                                                          </>
                                                      );
                                                  }
                                                  return <></>;
                                              })
                                            : ''}
                                    </div>
                                </div>

                                <div className="Module-Overview-Detail-Item">
                                    Certifications:{' '}
                                    <div>
                                        {module?.certifications?.length
                                            ? JSON.parse(
                                                  module?.certifications,
                                              )?.map((cert: any, i: number) => {
                                                  if (
                                                      module
                                                          ?.certificationsDetails
                                                          ?.length
                                                  ) {
                                                      return (
                                                          <>
                                                              <div>
                                                                  <Typography>
                                                                      {i +
                                                                          1 +
                                                                          ') ' +
                                                                          cert +
                                                                          ': ' +
                                                                          JSON.parse(
                                                                              module?.certificationsDetails,
                                                                          )?.[
                                                                              i
                                                                          ]}
                                                                  </Typography>
                                                              </div>
                                                          </>
                                                      );
                                                  }
                                                  return <></>;
                                              })
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {docs?.length ? (
                        <Grid container direction="row">
                            <Grid xs={12} sx={{ padding: '5px' }}>
                                <div className="Module-Overview-Details">
                                    <div className="Module-Overview-Detail-Item">
                                        Supporting Documents:
                                    </div>
                                    <div className="Module-Overview-Detail-Item">
                                        {docs?.map((doc) => {
                                            return (
                                                <iframe
                                                    key={doc?.id}
                                                    width={
                                                        docManagement
                                                            ? '99%'
                                                            : '150'
                                                    }
                                                    height={
                                                        docManagement
                                                            ? '500'
                                                            : '100'
                                                    }
                                                    src={
                                                        docManagement
                                                            ? doc?.link
                                                            : doc?.link +
                                                              '#toolbar=0'
                                                    }
                                                ></iframe>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid
                        container
                        sx={{
                            padding: '10px',
                            borderRadius: '20px',
                            boxShadow:
                                'rgb(0, 0, 0) 0px 2px 11px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                        }}
                    >
                        <Grid
                            xs={12}
                            sx={{ width: '700px', minHeight: '441px' }}
                        >
                            {pages?.length ? (
                                <div className="slider-container">
                                    <Slider {...settings}>
                                        {pages?.map((page, i) => (
                                            <div
                                                key={'page-preview-' + page.id}
                                            >
                                                {i === 0 ? (
                                                    <Overlay show={true} />
                                                ) : (
                                                    <></>
                                                )}
                                                <Card>
                                                    <h3
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        Page {page.pageNumber}{' '}
                                                        Preview
                                                    </h3>
                                                    <PagePreviewForCarousel
                                                        page={page}
                                                        currentPage={page.id}
                                                        classID={1}
                                                    />
                                                </Card>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            ) : (
                                <></>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};
